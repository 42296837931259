
import { defineComponent, ref, reactive, watch, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

import { msalInstance, loginRequest } from "@/authConfig";

import { Login } from "@/requests/oldLogin";

export default defineComponent({
  name: "verify-email",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    // Se entro nella pagina, senza esser passato da signin, torno indietro
    const credentials = computed(() => store.getters.credentials);
    if (!credentials.value.username || !credentials.value.password)
      router.push({ name: "sign-in" });

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      mail: Yup.string()
        .email("Inserire una mail valida")
        .required("Inserire una mail")
        .label("Email"),
      confirmMail: Yup.string()
        .email()
        .required()
        .label("Email")
        .oneOf([Yup.ref("mail"), null], "Le due mail devono coincidere"),
    });

    //Form submit function
    const onSubmit = async (values) => {
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT);
      Swal.fire({
        title: "Sei Sicuro?",
        icon: "warning",
        html: "Una volta confermata la mail non potrà essere modificata. <br> <br> Successivamente potrai accedere al management utilizzando la mail inserita e la password attuale.",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(async (res) => {
        if (res.isDismissed) return;

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          // Activate indicator
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        // Send login request
        // await store.dispatch("EmailChange", values);
        store.commit("setEmailInCredentials", values);
        await Login(credentials.value).then((res) => {
          if (res.status === 200) {
            const expires = new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            );
            document.cookie = `changed_credentials=true; expires=${expires}`;
            msalInstance.loginRedirect(loginRequest);
          } else {
            Swal.fire({
              text: res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. riprovare più tardi",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Chiudi",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            }).then(() => {
              // eslint-disable-next-line
              submitButton.value!.disabled = false;
              // Activate indicator
              // eslint-disable-next-line
              submitButton.value!.setAttribute("data-kt-indicator", "off");
            });
          }
        });
        // const [errorName] = Object.keys(store.getters.getErrors);
      });
    };

    return {
      onSubmit,
      login,
      submitButton,
      credentials,
      oldEmail: computed(() => store.getters.oldEmail),
    };
  },
});
